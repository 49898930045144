var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-scrollbar",
        { staticStyle: { height: "calc(100vh - 64px)" } },
        [
          _c(
            "transition",
            {
              attrs: {
                duration: { enter: 800, leave: 100 },
                mode: "out-in",
                name: "el-fade-in-linear"
              }
            },
            [
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-vertical",
                  attrs: {
                    collapse: _vm.isCollapse,
                    "collapse-transition": true,
                    "default-active": _vm.active,
                    "active-text-color": "#fff",
                    "text-color": "rgb(191, 203, 217)",
                    "unique-opened": ""
                  },
                  on: { select: _vm.selectMenuItem }
                },
                [
                  _vm._l(_vm.asyncRouters[0].children, function(item) {
                    return [
                      !item.hidden
                        ? _c("aside-component", {
                            key: item.name,
                            attrs: { routerInfo: item }
                          })
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }